import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUploadStore = defineStore('upload', () => {
  const show = ref(false)
  const delivery = ref({})

  function setShow (value) {
    show.value = value
    if (!value) delivery.value = {}
  }

  function setDelivery (value) {
    delivery.value = value
  }

  function saveImage (image) {
    console.log('Guardado')
  }

  return { show, delivery, setShow, setDelivery, saveImage }
})
