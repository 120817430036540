import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useNotificationStore } from '@/stores/notification'
import { useTokenStore } from '@/stores/token'
import { useGraphqlStore } from '@/stores/graphql'

export const useDeliveryStore = defineStore('delivery', () => {
  const notification = useNotificationStore()
  const graphql = useGraphqlStore()

  const deliveries = ref([])

  const startPoint = { type: 'point', name: 'Inicio', addressRecipient: 'inicio', position: { lat: '4.607813029203303', lng: '-74.13779835952454' } }
  const endPoint = { type: 'point', name: 'Fin', addressRecipient: 'fin', position: { lat: '4.607813029203303', lng: '-74.13779835952454' } }

  function restartStoredPositions () {
    const startStringPosition = localStorage.getItem('startPosition')
    const endStringPosition = localStorage.getItem('endPosition')

    if (startStringPosition) {
      const startPosition = JSON.parse(startStringPosition)
      deliveries.value[0].position.lat = startPosition.lat
      deliveries.value[0].position.lng = startPosition.lng
    }

    if (endStringPosition) {
      const endPosition = JSON.parse(endStringPosition)
      deliveries.value[deliveries.value.length - 1].position.lat = endPosition.lat
      deliveries.value[deliveries.value.length - 1].position.lng = endPosition.lng
    }
  }

  function setStartPoint ({ lat, lng }) {
    deliveries.value[0].position.lat = lat
    deliveries.value[0].position.lng = lng
    localStorage.setItem('startPosition', JSON.stringify({ lat, lng }))
  }

  function setEndPoint ({ lat, lng }) {
    deliveries.value[deliveries.value.length - 1].position.lat = lat
    deliveries.value[deliveries.value.length - 1].position.lng = lng
    localStorage.setItem('endPosition', JSON.stringify({ lat, lng }))
  }

  async function getDeliveries () {
    const token = useTokenStore()
    if (!token) notification.showNotification({ text: 'No te encuentras logueado', type: 'error' })
    const dni = token.getPayload(token.getToken())?.dni

    if (!dni) notification.showNotification({ text: 'Error al encontrar el usuario, comunicate con el administrador', type: 'error' })

    const variables = { search: 'bogota', searchBy: 'locationRecipient', search1: dni, searchBy1: 'dniDeliveryMan', limit: 200, page: 1, sort: 'createdAt', order: '-' }

    try {
      const responseSahet = await graphql.sendRequest('GET_ALL_DELIVERIES', variables, { isSahet: true })

      const wayPointsSahet = responseSahet?.data?.getDeliveries?.docs || []

      wayPointsSahet.forEach(wayPoint => { wayPoint.isSahet = true })

      deliveries.value = [startPoint, ...wayPointsSahet, endPoint]

      return true
    } catch (error) {
      notification.showNotification({ text: error.message, type: 'error' })
    }
  }

  async function updatePosition ({ delivery, lat, lng, isSahet }) {
    const variables = { delivery, lat: lat.toString(), lng: lng.toString() }

    try {
      const response = await graphql.sendRequest('UPDATE_POSITION_DELIVERY', variables, { isSahet })
      return response?.data?.updatePositionDelivery
    } catch (error) {
      notification.showNotification({ text: error.message + ' No se pudo actualizar la ubicación en la base de datos', type: 'error' })
    }
  }

  function moveDelivery ({ delivery, position }) {
    const deliveryIndex = deliveries.value.findIndex(d => d.delivery === delivery)
    if (!(deliveryIndex >= 0)) return

    const deliveryFound = deliveries.value[deliveryIndex]

    deliveries.value.splice(deliveryIndex, 1)
    deliveries.value.splice(position, 0, deliveryFound)
  }

  function addImage ({ delivery, image }) {
    const deliveryIndex = deliveries.value.findIndex(d => d.delivery === delivery)
    if (!(deliveryIndex >= 0)) return

    const deliveryFound = deliveries.value[deliveryIndex]

    deliveryFound.receipts.push(image)
    console.log(deliveryFound.receipts)
  }

  return { deliveries, getDeliveries, updatePosition, startPoint, endPoint, setStartPoint, setEndPoint, restartStoredPositions, moveDelivery, addImage }
})
