<template lang="pug">
</template>

<script setup>
/* eslint-disable no-undef */
import { ref, onMounted, onBeforeUnmount, watch, unref } from 'vue'

const props = defineProps({
  google: { type: Object, required: true },
  map: { type: Object, required: true },
  location: { type: Object, required: true },
  draggable: { type: Boolean, default: true },
  text: { type: String, default: '' },
  important: { type: Boolean, default: false },
  order: { type: Number, default: 0 },
  select: { type: Boolean, default: false }
})

const emit = defineEmits(['select', 'positionChange', 'created', 'delete'])

let marker

onMounted(async () => {
  let text = props.location.address === 'inicio' || props.location.address === 'fin' ? '' : (props.location.order).toString() + ')'
  if (props.location.text) text += props.location.text
  const { icon, shape } = getIcon(text)
  const { height, width } = shape
  icon.shape = {
    coords: [0, 0, width / 2, height],
    type: 'rect',
    fillColor: 'blue'
  }

  marker = await new props.google.maps.Marker({
    position: props.location.position || props.location.geometry.location,
    map: props.map,
    title: props.location.address,
    label: {
      text,
      className: props.important ? 'marker-position-important' : 'marker-position'
    },
    animation: props.google.maps.Animation.DROP,
    draggable: props.location.draggable ?? true,
    icon
  })

  emit('created', marker)

  props.map.panTo(marker.position)

  addListeners(marker)
})

onBeforeUnmount(() => {
  marker.setMap(null)
  marker = null
})

const isDrag = ref(false)

function addListeners (marker) {
  marker.addListener('dragstart', () => {
    isDrag.value = true
  })

  marker.addListener('dragend', () => {
    const lat = marker.position.lat()
    const lng = marker.position.lng()
    emit('positionChange', { lat, lng })
  })

  marker.addListener('mouseup', () => {
    if (!props.location.delivery) return
    if (props.select && !isDrag.value) emit('select', { deliveryName: props.location.delivery, center: false })
    if (isDrag.value) isDrag.value = false
  })
}

function getPathCloud (dx = 22, dy = 11, heightCurve = 3) {
  const pickHeight = heightCurve * 1.5

  // Initial Point
  const initial = `M 0 ${heightCurve}`
  // Curve top left
  const curveTL = `C 0 ${heightCurve}, 0 0, ${heightCurve} 0`
  // Line Top
  const lineTop = `H ${dx - heightCurve}`
  // Curve top right
  const curveTR = `C ${dx - heightCurve} 0, ${dx} 0, ${dx} ${heightCurve}`
  // Line Top
  const lineRight = `V ${dy - heightCurve}`
  // Curve bottom right
  const curveBR = `C ${dx} ${dy - heightCurve}, ${dx} ${dy}, ${dx - heightCurve} ${dy}`
  // Line Bottom Right
  const lineBottomR = `H ${(dx / 2) + heightCurve}`
  // Pick
  const pick = `L ${(dx / 2)} ${dy + pickHeight} L ${(dx / 2) - heightCurve} ${dy}`
  // Line Bottom Left
  const lineBottomL = `H ${heightCurve}`
  // Curve bottom left
  const curveBL = `C ${heightCurve} ${dy}, 0 ${dy}, 0 ${dy - heightCurve}`
  // Line Left
  const lineLeft = `V ${heightCurve}`

  const path = `${initial} ${curveTL} ${lineTop} ${curveTR} ${lineRight} ${curveBR} ${lineBottomR} ${pick} ${lineBottomL} ${curveBL} ${lineLeft}`

  return { path, height: dy + pickHeight, width: dx }
}

function getIcon (label, draggable) {
  const widthCloud = label?.length * 3 + 10 || 12
  const { path, height, width } = getPathCloud(widthCloud)

  return {
    icon: {
      path,
      fillColor: props.important ? '#ff5968' : '#fffa59',
      fillOpacity: 0.9,
      strokeWeight: draggable ? 4 : 1,
      strokeColor: 'Black',
      rotation: 0,
      scale: 2,
      labelOrigin: new props.google.maps.Point(widthCloud / 2, 5),
      anchor: new props.google.maps.Point(width / 2, height)
    },
    shape: {
      height,
      width
    }
  }
}

watch(() => props.draggable, (newVal) => {
  marker.setDraggable(newVal)

  let text = props.location.address === 'inicio' || props.location.address === 'fin' ? '' : (props.location.order).toString() + ')'
  if (props.location.text) text += props.location.text

  const { icon } = getIcon(text, newVal)
  marker.setIcon(icon)
})

watch(() => props.location.order, (newVal) => {
  let text = props.location.address === 'inicio' || props.location.address === 'fin' ? '' : (props.location.order).toString() + ')'
  if (props.location.text) text += props.location.text
  marker.setLabel({
    text,
    className: props.important ? 'marker-position-important' : 'marker-position'
  })
  const { icon } = getIcon(text)
  marker.setIcon(icon)
})
</script>

<style lang="stylus">
.marker-position, .marker-position-important
  font-size 1.1em !important
  color #333 !important
  font-weight 600

.marker-position-important
  color #fff !important
</style>
