module.exports = {
  maps: {
    API_KEY: 'AIzaSyCtOZfI6aImtKgvqlGTfaqbXZMzkCJGQkA'
  },
  graphql: {
    // uri: 'http://192.168.1.124:3000/graphql',
    uri: 'https://db.sahet.co/graphql',
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },

      mutate: {
        errorPolicy: 'all'
      }
    }
  }
}
