import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RoutesView from '../views/RoutesView.vue'
import CurrentDelivery from '@/components/views/routes/CurrentDelivery.vue'
import UploadImage from '@/components/cam/UploadImage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/routes',
    name: 'routes',
    component: RoutesView,
    children: [
      {
        path: 'current',
        component: CurrentDelivery,
        children: [
          {
            path: 'upload',
            component: UploadImage
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
