export default {
  GET_ALL: `#graphql
  query getDeliveries($search: String, $searchBy: String, $search1: String, $searchBy1: String, $limit: Int, $page: Int, $sort: String, $order: String) {
    getDeliveries(search: $search, searchBy: $searchBy, search1: $search1, searchBy1: $searchBy1, limit: $limit, page: $page, sort: $sort, order: $order)
    { 
      docs {
        delivery,
        order,
        status,
        observations {
          observation,
          createdAt,
          createdBy
        },
        position {
          lat,
          lng
        },
        charge,
        replacementCharge,
        addressRecipient,
        locationRecipient,
        departmentRecipient,
        nameRecipient,
        addressRecipient,
        phonesRecipient,
        conveyor,
        cost,
        tracking,
        dniDeliveryMan,
        nameDeliveryMan,
        createdBy,
        createdAt,
        deliveredBy,
        deliveredAt,
        inProgressAt,
        inProgressBy,
        declinedAt,
        declinedBy,
        deliveredAt,
        deliveredBy,
        leftBuildingReception,
        paidAt,
        paidBy,
        collectedAt,
        collectedBy,
        type,
        brand,
        receipts
      },
      totalDocs,
      limit,
      hasPrevPage,
      hasNextPage,
      page,
      totalPages,
      prevPage,
      nextPage,
      pagingCounter
    }            
  }`,
  UPDATE_POSITION: `#graphql
  mutation updatePositionDelivery($delivery: String!, $lat: String!, $lng: String!) {
    updatePositionDelivery(delivery: $delivery, lat: $lat, lng: $lng)        
  }`
}
