import { defineStore } from 'pinia'

export const useTokenStore = defineStore('token', () => {
  const expiresDays = 365

  function getToken () {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].search('authorization=Bearer') >= 0) {
        return cookies[i].replace('authorization=Bearer ', '').trim()
      }
    }
  }

  function setToken (token) {
    const date = new Date()
    date.setTime(date.getTime() + (expiresDays * 24 * 60 * 60 * 1000))
    document.cookie = `authorization=Bearer ${token};expires=${date.toUTCString()};SameSite=Strict`
  }

  function getPayload (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))

    return JSON.parse(jsonPayload)
  }

  function removeToken () {
    document.cookie = 'authorization=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  return { getToken, setToken, getPayload, removeToken }
})
