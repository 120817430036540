export default {
  SEND_VERIFICATION_CODE: `#graphql
  query SendVerificationCode($dni: String!, $sendToEmail: Boolean!, $sendToMobile: Boolean!) {
    sendVerificationCode(dni: $dni, sendToEmail: $sendToEmail, sendToMobile: $sendToMobile)
  }`,

  LOGIN: `#graphql
  query login($dni: String!, $code: String!) {
    login(dni: $dni, code: $code)
  }`
}
