import { ref, watch } from 'vue'

export function useMap ({ props, $Map }) {
  const mapLoading = ref(null)
  const mapError = ref('')

  const map = ref(null)
  const mapExtraOptions = ['zoomControl', 'mapTypeControl', 'scaleControl', 'streetViewControl', 'rotateControl', 'fullscreenControl', 'mapId']

  let mounted = false

  function mount () {
    mapLoading.value = true

    map.value = createMap($Map.value, { center: props.center, zoom: props.zoom }, props.extraOptions)
    mounted = true

    mapLoading.value = false
  }

  function createMap (div, options, extraOptions) {
    Object.assign(options, getCleanOptions(extraOptions))

    try {
      return new props.google.maps.Map(div, options)
    } catch (err) {
      mapError.value = err.message
    }
  }

  function getCleanOptions (userOptions) {
    return mapExtraOptions.reduce((options, option) => {
      if (option in userOptions) options[option] = userOptions[option]
      return options
    }, {})
  }

  watch(props, (newValue) => {
    if (newValue.google !== null && !mounted) mount()
  })

  return { mapLoading, mapError, map }
}
