import { defineStore } from 'pinia'
import { graphql as config } from '@/config'
import graphql from '@/graphql'
import { useTokenStore } from '@/stores/token'

export const useGraphqlStore = defineStore('graphql', () => {
  async function sendRequest (query, variables = {}, options = {}, body) {
    if (!graphql[query]) throw new Error(`The graphql query "${query}" does not exits`)

    const { method, JWT } = options

    const token = useTokenStore()

    let requestData

    if (!body) {
      requestData = {
        method: method || 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          token: JWT || token.getToken()
        },
        body: JSON.stringify({ query: graphql[query], variables })
      }
    } else {
      requestData = {
        method: method || 'POST',
        body
      }
    }

    const response = await fetch(config.uri, requestData)
    if (!response) throw new Error('El servidor no respondió')

    const data = await response.json()
    if (data?.errors?.[0]) throw new Error(data.errors[0].message)

    return data
  }

  return { sendRequest }
})
