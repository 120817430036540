import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'

const pinia = createPinia()
const app = createApp(App)

const addValueToString = (value, position, addValue) => {
  if (!value) {
    return ''
  } else {
    const array = value.split('')

    if (position > array.length - 1) {
      return value
    } else {
      array.splice(position, 0, addValue)
      return array.join('')
    }
  }
}

app.config.globalProperties.$filters = {
  firstName (value) {
    if (!value) return ''
    return value.trim().split(' ')[0]
  },
  money (value, wordFree = '') {
    if (wordFree && value === 0) return wordFree
    if (value === 0) return '$ 0'
    if (!value) return value

    try {
      return `$ ${new Intl.NumberFormat('es-CO').format(value)}`
    } catch (error) {
      return value
    }
  },
  mobile (value) {
    return addValueToString(addValueToString(value, 6, ' '), 3, ' ')
  }
}

app
  .use(router)
  .use(pinia)
  .mount('#app')
