import login from './login'
import delivery from './delivery'
import image from './image'

// Queries
export default {
  // Login
  LOGIN: login.LOGIN,
  SEND_VERIFICATION_CODE: login.SEND_VERIFICATION_CODE,

  // Delivery
  GET_ALL_DELIVERIES: delivery.GET_ALL,
  UPDATE_POSITION_DELIVERY: delivery.UPDATE_POSITION,

  // Image
  UPLOAD_IMAGE_DELIVERY: image.UPLOAD_IMAGE_DELIVERY
}
