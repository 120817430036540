import { ref, watch } from 'vue'

export function useAutocomplete ({ props, $Autocomplete }) {
  const autocompleteLoading = ref(null)
  const autocompleteError = ref('')

  let mounted = false

  const autocomplete = ref(null)
  const options = {
    componentRestrictions: { country: 'co' },
    fields: ['geometry']
  }

  function mount () {
    autocompleteLoading.value = true

    autocomplete.value = createAutocompleteInput($Autocomplete.value, options)
    mounted = true

    autocompleteLoading.value = false
  }

  function createAutocompleteInput (input, options) {
    try {
      return new props.google.maps.places.Autocomplete(input, options)
    } catch (err) {
      autocompleteError.value = err.message
    }
  }

  watch(props, (newValue) => {
    if (newValue.google !== null && !mounted) mount()
  })

  return { autocompleteLoading, autocompleteError, autocomplete }
}
